import { LinkWrapper } from "@/components/link-wrapper";
import { ModuleSpacer } from "@/components/module-spacer";
import { Title } from "@/components/title";
import type { ModuleSideBySideFieldsFragment } from "@/lib/contentful/__generated/graphql.types";
import {
    Box,
    CldImage,
    CldVideo,
    CldVideoPlayerProvider,
    CldVideoTogglePlay,
    Flex,
    Link,
    Text,
    hasCloudinaryAsset,
    isCloudinaryVideo,
    Grid,
    GridItem,
} from "@project/ui";
import type { GridProps, GridItemProps } from "@project/ui";
import { motion } from "framer-motion";

export type ModuleSideBySideProps = ModuleSideBySideFieldsFragment;

const SPACE_5 = "var(--space-5)";
const SPACE_10 = "var(--space-10)";

const SixColumnGrid = ({ children, ...props }: GridProps) => (
    <Grid
        templateColumns={{
            base: "1fr",
            l: "repeat(6, 1fr)",
        }}
        {...props}
    >
        {children}
    </Grid>
);

const SixColumnGridItem = ({ children, ...props }: GridItemProps) => (
    <GridItem
        gridColumn={{
            base: "1/2",
            l: "1/5",
        }}
        {...props}
    >
        {children}
    </GridItem>
);

export const ModuleSideBySide = (props: ModuleSideBySideProps) => {
    const { title, text, introductionCaption, asset, link, linkLabel, reverse = true } = props;

    return (
        <ModuleSpacer className="ModuleSideBySide">
            <Flex
                flexDirection={{ base: "column", l: reverse ? "row-reverse" : "row" }}
                py={{ base: 5, l: 10 }}
                gap={{ base: 4, l: 8 }}
            >
                <Box
                    flex={1}
                    sx={{ aspectRatio: { base: "1/1", xs: "16/9", l: "unset" } }}
                    position="relative"
                >
                    {hasCloudinaryAsset(asset) && (
                        <>
                            {isCloudinaryVideo(asset) ? (
                                <CldVideoPlayerProvider autoplay muted loop>
                                    <CldVideo
                                        cloudinaryAsset={asset}
                                        loop
                                        wrapperProps={{ overflow: "hidden" }}
                                        animate
                                    />
                                    <CldVideoTogglePlay
                                        sx={{
                                            position: "absolute",
                                            right: [SPACE_5, SPACE_10],
                                            bottom: [SPACE_5, SPACE_10],
                                        }}
                                    />
                                </CldVideoPlayerProvider>
                            ) : (
                                <CldImage cloudinaryAsset={asset} fill animate />
                            )}
                        </>
                    )}
                </Box>
                <Flex
                    flex={1}
                    flexDirection="column"
                    justifyContent="start"
                    minH={{ base: "auto", l: "calc(100vh - var(--space-10) )" }}
                >
                    <Text size="caption" mb={{ base: 4, l: 20 }}>
                        {introductionCaption}
                    </Text>
                    <SixColumnGrid>
                        <SixColumnGridItem>
                            <Box
                                as={motion.div}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: "all" }}
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {
                                            duration: 0.6,
                                            ease: [0, 0.61, 0.37, 1],
                                            delay: 0.2,
                                        },
                                    },
                                    hidden: { opacity: 0, y: 20 },
                                }}
                            >
                                <Title size="displayMedium" sx={{ textWrap: "balance" }}>
                                    {title}
                                </Title>
                            </Box>
                        </SixColumnGridItem>
                    </SixColumnGrid>
                    <Flex mt={{ base: 32, l: "auto" }} flexDirection="column" align="start">
                        <SixColumnGrid
                            templateColumns={{
                                base: "1fr",
                                l: "repeat(6, 1fr)",
                            }}
                            width="full"
                        >
                            <SixColumnGridItem>
                                <Text
                                    sx={{
                                        textWrap: "balance",
                                    }}
                                >
                                    {text}
                                </Text>
                            </SixColumnGridItem>
                        </SixColumnGrid>
                        {link && (
                            <LinkWrapper
                                mt={{ base: 4, l: 8 }}
                                as={Link}
                                item={link}
                                icon="arrow-right"
                            >
                                {linkLabel}
                            </LinkWrapper>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </ModuleSpacer>
    );
};
