import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import type { CoreGraphqlEntryProps } from "@/types/page";
import { useModuleSideBySideQuery } from "@/components/contentful/module-side-by-side/__generated/module-side-by-side.contentful.generated";
import { ModuleSideBySide } from "@/components/contentful/module-side-by-side/module-side-by-side";

export type ModuleSideBySideGraphqlProps = CoreGraphqlEntryProps;

export const ModuleSideBySideGraphql = ({ id, locale, preview }: ModuleSideBySideGraphqlProps) => {
    const { data, isLoading } = useModuleSideBySideQuery(
        {
            id,
            locale,
            preview,
        },
        {
            ...(!preview && { staleTime: Infinity }),
            refetchOnWindowFocus: false,
        }
    );

    const moduleSideBySide = useContentfulLiveUpdates(data?.moduleSideBySide);

    if (isLoading || !moduleSideBySide) {
        return null;
    }

    return <ModuleSideBySide {...moduleSideBySide} />;
};
